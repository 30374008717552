<template>
  <v-tabs v-model="_tab" height="40" grow show-arrows hide-slider>
    <v-tab v-for="tab in tabs" :key="tab.value">
      {{ tab.text }}
    </v-tab>
  </v-tabs>
</template>

<style lang="scss" scoped>
.v-tabs::v-deep {
  height: 40px;
  .v-item-group {
    // width 넘어갔을때 나오는 arrow
    .v-slide-group__prev,
    .v-slide-group__next {
      flex: auto;
      min-width: 32px;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }
  }
  .v-tab {
    min-width: 120px;
    border: thin solid transparent;
    border-top-color: rgba(0, 0, 0, 0.12);
    &.v-tab--active {
      font-weight: bold;
      border: thin solid rgba(0, 0, 0, 0.12);
      // border-top-color: transparent;
      border-top: none;
      &::before {
        top: -1px;
        opacity: 0.12;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    _tab: {
      get() {
        return this.tab;
      },
      set(tab) {
        this.$emit("change:tab", tab);
      }
    }
  }
};
</script>
